<script setup lang="ts">
   import {
      Listbox,
      ListboxButton,
      ListboxOption,
      ListboxOptions,
   } from '@headlessui/vue';
   import { ChevronDownIcon } from '@heroicons/vue/20/solid';
   import { currencyList } from '@/const/currencyList';
   import { useCurrencyStore } from '@/stores/currency.pinia.ts';

   interface Currency {
      code: string;
      name: string;
      flag: string;
   }

   const { currentCurrency } = storeToRefs(useCurrencyStore());

   const props = defineProps<{
      modelValue?: number;
      onlyView?: boolean;
   }>();

   const emit = defineEmits<{
      (event: 'update:modelValue', value: number): void;
      (event: 'select', value: string): void;
   }>();
   const { t } = useI18n();

   const selectedCurrency = ref<Currency>(currentCurrency.value);
   const currencies = ref(currencyList);
   const changeCurrency = async (currency: Currency) => {
      selectedCurrency.value = currency;
      useCurrencyStore().updateCurrency(currency);

      if (props.onlyView) {
         return emit('select', currency.code);
      }
   };

   onMounted(async () => {
      await useCurrencyStore().detectUserCurrency();
      selectedCurrency.value = currentCurrency.value;
      currencies.value = currencyList;
   });
</script>

<template>
   <Listbox v-model="selectedCurrency">
      <div class="relative">
         <ListboxButton
            class="relative flex items-center justify-between cursor-default rounded-lg bg-transparent py-1 px-2 sm:py-3 sm:px-4 text-xs sm:text-sm text-gray-900 hover:bg-gray-50 w-full"
         >
            <div class="flex cursor-pointer items-center">
               <!-- Added min-w-0 to allow text truncation -->
               <span
                  class="flex-shrink-0 me-2 rounded-full text-xs sm:text-2xl"
                  >{{ selectedCurrency?.flag }}</span
               >
               <span class="">
                  {{ t(`country.${selectedCurrency?.name}`) }} -
                  {{ t(`currency.${selectedCurrency?.code}`) }}
               </span>
            </div>
            <ChevronDownIcon
               class="flex-shrink-0 h-5 w-5 ml-2"
               aria-hidden="true"
            />
         </ListboxButton>

         <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
         >
            <ListboxOptions
               class="absolute mt-1 h-32 sm:min-h-96 min-w-56 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
            >
               <ListboxOption
                  v-for="(currency, index) in currencies"
                  :key="currency.code"
                  :value="currency"
                  v-slot="{ active }"
                  @click="changeCurrency(currency)"
                  :class="index === currencies.length - 1 ? 'mb-4 sm:mb-0' : ''"
               >
                  <li
                     :class="[
                        active ? 'bg-gray-50' : '',
                        'relative cursor-default select-none py-3 px-4',
                     ]"
                  >
                     <div class="flex text-xs sm:text-sm items-center min-w-0">
                        <span
                           class="flex-shrink-0 bg-gray rounded-full text-sm sm:text-xl me-2"
                           >{{ currency.flag }}</span
                        >
                        <span class="truncate">
                           {{ t(`country.${currency?.name}`) }} -
                           {{ t(`currency.${currency?.code}`) }}
                        </span>
                     </div>
                  </li>
               </ListboxOption>
            </ListboxOptions>
         </transition>
      </div>
   </Listbox>
</template>
